




























































import { promiseHelper } from '@/helpers/promise-helper'
import { Observer } from 'mobx-vue'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { walletStore } from '@/stores/wallet-store'
import { alertController } from '@/components/alert/alert-controller'
import { MarketKeyInfo } from '@/blockchainHandlers/ido-contract-interface'
import { PoolStore } from '@/modules/ido/stores/pool-store'
import web3 from 'web3'
import { SolidityIdoContract } from '@/blockchainHandlers/ido-contract-solidity'
import { SolanaIdoContract } from '@/blockchainHandlers/ido-contract-solana'
import { PublicKey } from '@solana/web3.js'
import { get } from 'lodash-es'
@Observer
@Component({
  components: {
    HoverImage: () => import('./hover-image.vue'),
  },
})
export default class TransferDialog extends Vue {
  error = ''
  dialog = false
  loading = false

  walletAddress = ''

  keyInfo: MarketKeyInfo | null = null
  poolStore: PoolStore | null = null

  resolve?: (any) => void

  async open(keyInfo: MarketKeyInfo, poolStore: PoolStore) {
    this.error = ''
    this.walletAddress = ''

    this.keyInfo = keyInfo
    this.poolStore = poolStore
    this.dialog = true
    await promiseHelper.delay(100)
    return new Promise((r) => (this.resolve = r))
  }

  async cancel(result = false) {
    this.resolve && this.resolve(result)
    this.dialog = false
    await promiseHelper.delay(100)
    this.keyInfo = null
    this.poolStore = null
    this.error = ''
  }

  async confirmTransfer() {
    this.loading = true
    try {
      const isDisableTransfer = get(this.poolStore, 'pool.data.disableTransfer')
      if (isDisableTransfer) {
        throw 'This NFT cannot be sold or transferred'
      }
      if (this.poolStore?.chain === 'sol') {
        try {
          new PublicKey(this.walletAddress).toBytes()
        } catch (error) {
          this.error = 'Invalid Wallet Address'
          return
        }
        // if (!PublicKey.isOnCurve(new PublicKey(this.walletAddress).toBytes())) {
        //   this.error = 'Invalid Wallet Address'
        //   return
        // }
      } else {
        if (!web3.utils.isAddress(this.walletAddress)) {
          this.error = 'Invalid Wallet Address'
          return
        }
      }
      const ok = await alertController.confirm(
        'Confirm transfer',
        `Your asset will be transferred to address
<br />
<span class="primary--text font-weight-bold">${this.walletAddress}</span>
<br />
So it will no longer be in your wallet nor will it show up in your dashboard.`,
        'Confirm',
        'Cancel'
      )
      if (ok && this.keyInfo) {
        if (this.poolStore?.chain === 'sol') {
          const contract = this.poolStore?.contract as SolanaIdoContract
          if (contract) {
            await contract.transferMarketKey(walletStore.account, this.keyInfo.id, this.walletAddress)
          } else {
            return
          }
        } else {
          const nftContract = (this.poolStore?.contract as SolidityIdoContract)?.marketNftKey
          if (nftContract) {
            await nftContract.transfer(
              walletStore.account,
              web3.utils.toChecksumAddress(this.walletAddress),
              this.keyInfo.id
            )
          } else {
            return
          }
        }
        snackController.success(
          `You have transferred NFT #${this.keyInfo.id} to address ${this.walletAddress} successfully!`
        )
        this.walletAddress = ''
        this.$emit('doneTransfer')
        this.cancel(true)
      }
    } catch (error) {
      this.error = error.msg || error.message || error
      snackController.error(error)
    } finally {
      this.loading = false
    }
  }

  @Watch('walletAddress') onWalletAddressChanged() {
    this.error = ''
  }
}
